/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "@elit-it/mdb5/assets/scss/mdb";

body {
  background-color: #eef3f1;
  background-image: url("assets/page_bg.svg");
  background-position: center top 200px;
  background-repeat: no-repeat;
  background-size: 2153px auto;

  font-family: 'Red Hat Display', sans-serif;
  color: black;
}
*:not(i, svg) { font-family: 'Red Hat Display', sans-serif!important; }

h1,h2,h3,h4,h5,h6, .h1,.h2,.h3,.h4,.h5,.h6 {
  font-weight: 900;
  text-transform: uppercase;
}

a.rsr-btn, button.rsr-btn {
  overflow: hidden;
  border-radius: 3px 20px 3px 3px;

  color: #000000;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #abcc59;

  i,svg { margin-left: 1rem; }
}
a.rsr-btn:hover, button.rsr-btn:hover { background-color: #025b67; color: #ffffff; }
a.rsr-btn:not(.btn-custom):active, button.rsr-btn:not(.btn-custom):active { background-color: #025b67!important; color: #ffffff!important; }

// BACKGROUND
@media (max-width: 1500px) {
  body { background-size: 1600px auto; }
}
@media (max-width: 1100px) {
  body { background-size: 1300px auto; }
}
@media (max-width: 880px) {
  body { background-size: 1025px auto; }
}
@media (max-width: 550px) {
  body { background-size: 850px auto; }
}


.rsr-radius { border-radius: 10px 40px 10px 10px; overflow: hidden; }

.rsr-gradient { background: linear-gradient(180deg, #00000000 0%, #00000000 40%, #025B67 100%); }
.rsr-mask {
  padding: 10px 20px; color: white;
  h1,h2,h3,h4,h5,h6 { font-size: 1rem; line-height: 1.5rem; }
}

section { margin-bottom: 75px; margin-top: 75px; }
div.rsr-box {
  background-color: #ffffff;
  padding: 10px 20px;
  text-align: center;
  height: 100%;

  h2, .h2 { text-transform: none; font-size: 1.3rem; }
  h3, .h3 { text-transform: none; font-size: 1.2rem; }
  img { margin-bottom: .25rem; }
}

a { color: #025b67; transition-duration: .25s }
a:hover { color: #abcc59; transition-duration: .25s }
a.link-active { font-weight: 900; }
a.link-disabled { color: #9ca3af; cursor: default; }

.rsr-bullet::before { content: '\2022'; font-weight: 900; padding: 0 .25rem; }
.spinner-border { border-color: #025b67; border-right-color: transparent; }

// CK Content 5 Editor SCSSS rules
@import "@elit-it/ckeditor/style.css";
.ck-content figure > img { border-radius: 10px 40px 10px 10px; overflow: hidden; }
.ck-content figure:has(> figcaption) > img { border-radius: 10px 40px 0 0; overflow: hidden; }
.ck-content figure:has(> figcaption) > figcaption { border-radius: 0 0 10px 10px; overflow: hidden; }
.ck-content { > h2, > h3, > h4, > h5, > h6 { margin-top: 2rem; } }


// Angular material design
mat-form-field { width: 100%; }
.mat-datepicker-toggle-active { color: #025b67!important; }
.mat-calendar-body-selected { background-color: #025b67!important; }
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) { background-color: #025b673b!important; }
button.mat-calendar-body-cell.mat-calendar-body-disabled { cursor: auto; }
